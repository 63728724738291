.specimen-reception-text-label {
  font-size: 30px;
  font-weight: bold;
}

.specimen-reception-label {
  font-size: 13px;
  text-transform: capitalize;
}

.specimen-reception-label-value {
  font-weight: 600;
}

.specimen-lab-label {
  display: none;
}

.comment-error {
  color: red;
  font-size: 10px;
  text-transform: none;
}