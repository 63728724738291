.csv-import-container {
  padding: 20px;
}

.tracking-and-import {
  display: flex;
  flex-direction: row;
}

.tracking-field {
  flex: 5;
  margin-right: 40px;
}

.csv-input {
  padding: 10px;
  display: block;
  margin: 15px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.import-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
