.specimen-reception-container {
  padding: 20px;
}
.specimen-reception-header {
  margin: 20px 0;
}
.specimen-reception-header-label {
  font-size: 14px;
  font-weight: bold;
}

.specimen-reception-no-case{
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
  border: 1px solid;
  padding: 50px;
}
.specimen-reception-error {
  color: red;
  font-weight: bold;
  margin-top: 26px;
  margin-left: 20px;
}
.specimen-reception-footer-elements {
  display: flex;
  margin-bottom: 20px 
}
.specimen-reception-success {
  color: green;
  font-weight: bold;
  margin-top: 26px;
  margin-left: 20px;
}
.case-search-header{
  margin-bottom: 15px;
}
.locked-case-message {
  padding-left: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 16px;
  color: white;
  background-color: black;
}